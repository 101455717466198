
/**
 * CSS files with the .module.css suffix will be treated as CSS modules
 * and scoped locally.
 */

.exhibit-grid * {
    box-sizing: border-box !important;
}

.exhibit-grid *:focus {
  outline: none;
}
